import React, { useState } from "react"
import { Link } from "gatsby"

import Layout from "../mainComponents/Layout"
import Footer from "../mainComponents/Footer"
import FooterMail from "../components/FooterMail"
import SliderDefault from "../components/SliderDefault"
import SEO from "../mainComponents/seo"

import "../style/about.scss"

import LvivImageFirst from "../images/about/img-lviv-1.png"
import LvivImageSecond from "../images/about/img-lviv-2.png"
import LvivImageThird from "../images/about/img-lviv-3.png"

import { useMediaQuery } from "../components/isMediaSmall"
import Wrapper from "../mainComponents/Wrapper"

import MissionFirst from "../assets/about/mission-1.svg"
import MissionSecond from "../assets/about/mission-2.svg"
import MissionThird from "../assets/about/mission-3.svg"
import MissionFourth from "../assets/about/mission-4.svg"
import MissionFifth from "../assets/about/mission-5.svg"

const AboutPage = () => {
  const [sliderAction, setSliderAction] = useState("")

  const handleSliderAction = type => {
    switch (type) {
      case "prev":
        setSliderAction("prev")
        break

      case "next":
        setSliderAction("next")
        break

      default:
        break
    }

    setTimeout(() => {
      setSliderAction("")
    }, 200)
  }

  const isMediaSmall = useMediaQuery("(max-width: 1140px)")

  return (
    <Wrapper>
    <Layout>
      <SEO title="About us" description={"We merge art and business with best UX/UI and development."} />
      <section className="whywe container">
        <div className="whywe__block">
          <h2 className="whywe__title">Why work with us</h2>

          {isMediaSmall ? null : (
            <>
              <svg
                className="whywe__arrow"
                onClick={() => handleSliderAction("prev")}
                width="37"
                height="16"
                viewBox="0 0 37 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.292892 8.7071C-0.0976296 8.31658 -0.0976295 7.68341 0.292893 7.29289L6.65686 0.92893C7.04738 0.538405 7.68054 0.538405 8.07107 0.92893C8.46159 1.31945 8.46159 1.95262 8.07107 2.34314L2.41422 8L8.07107 13.6569C8.46159 14.0474 8.46159 14.6805 8.07107 15.0711C7.68054 15.4616 7.04738 15.4616 6.65685 15.0711L0.292892 8.7071ZM37 9L1 9L1 7L37 7L37 9Z"
                  fill="black"
                />
              </svg>

              <svg
                className="whywe__arrow"
                onClick={() => handleSliderAction("next")}
                width="37"
                height="16"
                viewBox="0 0 37 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M36.7071 8.7071C37.0976 8.31658 37.0976 7.68341 36.7071 7.29289L30.3431 0.92893C29.9526 0.538405 29.3195 0.538405 28.9289 0.92893C28.5384 1.31945 28.5384 1.95262 28.9289 2.34314L34.5858 8L28.9289 13.6569C28.5384 14.0474 28.5384 14.6805 28.9289 15.0711C29.3195 15.4616 29.9526 15.4616 30.3431 15.0711L36.7071 8.7071ZM8.74228e-08 9L36 9L36 7L-8.74228e-08 7L8.74228e-08 9Z"
                  fill="black"
                />
              </svg>
            </>
          )}
        </div>

        <SliderDefault action={sliderAction} />
      </section>

      <section className="ourhistory">
        <div className="container">
          <h2 className="ourhistory__title">Our history</h2>

          <div className="ourhistory-item">
            <div className="ourhistory-item__source">
              <img className="ourhistory-item__image" src={LvivImageFirst} alt="" />
            </div>

            <p className="ourhistory-item__text">
              In 2017, we launch our first startup UPrice, an app that converts pricing using detection tools and machine learning. Startup raises investments and successfuly gain market share within a few months of operations. It is then, that we identify a market gap in outsourcing model and desice to start our agency to help entreprneurs around the world improve their digital presnece and enhance customer experience.
            </p>
          </div>

          <div className="ourhistory-item">
            <p className="ourhistory-item__text">
              In 2019, we rebrand our software development shop to include design, R&D, marketing, and consulting services to provide full cycle support for our clients in one place.  At this time, we are recognized by international rating agencies and start exponentially growing in size and revenues. We continue emphasizing creativity and improve our workflows daily to minimize the time and maximize efficiency and resource allocation.
            </p>

            <div className="ourhistory-item__source">
              <img className="ourhistory-item__image" src={LvivImageSecond} alt="" />
            </div>
          </div>

          <div className="ourhistory-item">
            <div className="ourhistory-item__source">
              <img className="ourhistory-item__image" src={LvivImageThird} alt="" />
            </div>

            <p className="ourhistory-item__text">
              In 2020, we start investing in startups and open divisions globally focused on enhancing startup ecosystem. We separate our services into launchpad for startups, standard outsourcing model, freelancing hub, and internal product development division. We establish partnerships with marketing agencies and R&D firms to upgrade services and increase the scale of our impact. Later that year, we successfully enter North American market and establish Movadex umbrella fund.
            </p>
          </div>
        </div>
      </section>

      <section className="ourmission">
        <div className="ourmission__wrapper container">
          <h2 className="ourmission__title">Our mission</h2>

          <div className="ourmission__block">
            <div className="ourmission-item">
              <img className="ourmission-item__icon" src={MissionFirst} alt="" />
              <p className="ourmission-item__text">
                Advance startup ecosystem to create better economic conditions
              </p>
            </div>

            <div className="ourmission-item">
              <img className="ourmission-item__icon" src={MissionSecond} alt="" />
              <p className="ourmission-item__text">
                Revolutionize online world by upgrading digital presence
              </p>
            </div>

            <div className="ourmission-item">
              <img className="ourmission-item__icon" src={MissionThird} alt="" />
              <p className="ourmission-item__text">
                Aspire for excellence to secure high quality and flawless performance
              </p>
            </div>

            <div className="ourmission-item">
              <img className="ourmission-item__icon" src={MissionFourth} alt="" />
              <p className="ourmission-item__text">
                Bridge IT services gap through transformative communication tools
              </p>
            </div>

            <div className="ourmission-item">
              <img className="ourmission-item__icon" src={MissionFifth} alt="" />
              <p className="ourmission-item__text">
                Foster STEM education to positively impact future development and growth
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="teams">
        <div className="teams__wrapper container">
          <h2 className="teams__title">Movadex teams</h2>

          <div className="teams-card">
            <div className="teams-card__item">
              <h4 className="teams-card__title">Team of developers</h4>

              <div className="teams-card__content">
                <h5 className="teams-card__title_sm">Team of developers</h5>
                <p className="teams-card__text">
                  The team work closely with you accross all the development stages ensuring that the software runs smoothly and the user interface meets leading industry standards. Each professional know 5+ programming languages and full mastery of IDEs.
                </p>
              </div>
            </div>

            <div className="teams-card__item">
              <h4 className="teams-card__title">Team of designers</h4>

              <div className="teams-card__content">
                <h5 className="teams-card__title_sm">Team of designers</h5>
                <p className="teams-card__text">
                  The designers guarantee that your user is asbolutely mesmerized by your product. Our specialists are trained according to AGILE and they utilize these tools once prototyping your flowcharts and wireframes. The process is not only fully optimized, but also highly engaging.
                </p>
              </div>
            </div>

            <div className="teams-card__item">
              <h4 className="teams-card__title">Team of public outreach</h4>

              <div className="teams-card__content">
                <h5 className="teams-card__title_sm">Team of public outreach</h5>
                <p className="teams-card__text">
                  Public outreach works closely with our potential clients and partners providing all the information necessary to initiate the two-way conversation. The team is multi-lingual and is available 24/7, ensuring that anyone from around the world can reach us.
                </p>
              </div>
            </div>

            <div className="teams-card__item">
              <h4 className="teams-card__title">
                Team of client communication
              </h4>

              <div className="teams-card__content">
                <h5 className="teams-card__title_sm">Team of of client communication</h5>
                <p className="teams-card__text">
                  Client communication group is responsible for any interaction with the client pre, during, and after the services are provided. With momentarily response rate, they ensure that clients are taken care of immediately and every question is addressed swiftly and professionally.
                </p>
              </div>
            </div>

            <div className="teams-card__item">
              <h4 className="teams-card__title">Team of project management</h4>

              <div className="teams-card__content">
                <h5 className="teams-card__title_sm">Team of project management</h5>
                <p className="teams-card__text">
                  Our PMs not only oversee the clientele workflows but also ensure that our employees are learning every day and growign together with our company. SCRUM-sertified, they play an integral role to our successful operations.
                </p>
              </div>
            </div>

            <div className="teams-card__item">
              <h4 className="teams-card__title">Team of idea success</h4>

              <div className="teams-card__content">
                <h5 className="teams-card__title_sm">Team of idea success</h5>
                <p className="teams-card__text">
                  Team of idea success works on validating and improving any ideas that enter the funnel. From itneral startups to client’s ideas, our team will conduct expedited analysis of the market and its potential and offer the best scenario for entering the market with the new idea.
                </p>
              </div>
            </div>

            <div className="teams-card__item">
              <h4 className="teams-card__title">
                Team of financial & accounting specialists
              </h4>

              <div className="teams-card__content">
                <h5 className="teams-card__title_sm">Team of financial & accounting specialists</h5>
                <p className="teams-card__text">
                  Financial and accounting specialists are the backbone of our business. They offer world-class expertise in managing finances and help us to smooth bookkeeping and transaction processes. They are avaiable on-call to any of our client for further consultation.
                </p>
              </div>
            </div>

            <div className="teams-card__item">
              <h4 className="teams-card__title">Team of business analytics</h4>

              <div className="teams-card__content">
                <h5 className="teams-card__title_sm">Team of business analytics</h5>
                <p className="teams-card__text">
                  Team of business analytics ensures that our internal and externa management is development daily. They also conduct case studies on all of our clients offering ways to improve an o the business aspect they find to be incomplete. They bring data management to the next level.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

    </Layout>

    <Footer>
      <FooterMail />
    </Footer>
    </Wrapper>
  )
}

export default AboutPage
