import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from "gatsby"
import { useMediaQuery } from './isMediaSmall';

const SliderDefault = props => {
    const data = useStaticQuery(graphql`
    query SliderDefaultQuery {
        allSliderDefaultDataJson {
            edges {
                node {
                    id
                    title
                    text
                }
            }
        }
    }    
    `);

    const sliderData = data.allSliderDefaultDataJson.edges;

    const [item, setItem] = useState(0);
    const total = sliderData.length;

    const isMediaSmall = useMediaQuery("(max-width: 1140px)");

    useEffect(() => {
        switch (props.action) {
            case "prev":
                if(item === 0) {
                    setItem(0)
                } else {
                    setItem(item - 1);
                }
                break;
        
            case "next":
                if(item === total - 1) {
                    setItem(item)
                } else {
                    setItem(item + 1);
                }
                break;        
            default:
                break;
        }
    }, [props.action]);

    return (
        <>
        <div className="slider">
            <div className="slider__wrapper" style={isMediaSmall ? null : {transform: `translateX(-${item * (100 / total)}%)`}}>
                {sliderData.map(({ node }) =>
                <div className="slider-item" key={node.id}>
                    <h2 className="slider-item__title">{node.title}</h2>
    
                    <p className="slider-item__text">{node.text}</p>
                </div>)}
            </div>
        </div>
        </>
    );
}

export default SliderDefault;
